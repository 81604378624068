/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Image } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Menu"}>
        <SiteHeader set="cs" currentLanguage={0} />

        <Column className="--center pt--80" style={{"paddingBottom":0}} name={"potvrdit-ucast-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--48 mt--10" content={"VÝBĚR MENU"}>
              </Title>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":600}} content={"Chtěli bychom, abyste si naši svatbu užili po všech stránkách,<br>vyberte si prosím menu,\nkteré vám bude více vyhovovat.<br><br>Potvrďte nám prosím také, zda potřebujete zařídit parkování v hotelu Aria.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wsn1lkp0cme"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--color-blend--50);\">MENU 1</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--50);\">KREVETY<br>Wonton | xo | sezam | soja<br><br>CHŘEST<br>Panko | chili | tapioka | asijské bylinky\n\n<br><br>HALIBUT<br>Špenát | shitake | miso | ředkev<br><br>JAHODY<br>Čatní | pusinka | sorbet | čokoláda</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--color-blend--50);\">MENU 2</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--50);\">KACHNA<br>Grepfruit | edamame | zázvor | ponzu\n\n<br><br>CHŘEST<br>Panko | chili | tapioka | asijské bylinky\n\n<br><br>PICANHA<br>Černý pepř | brokoliny | koriandr chili - salsa\n\n<br><br>JAHODY<br>Čatní | pusinka | sorbet | čokoláda</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Title className="title-box fs--48" content={"<span style=\"caret-color: rgb(156, 147, 148); color: rgb(156, 147, 148);\">DĚTSKÉ MENU</span><br>"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--color-blend--50);\">KUŘE<br>Řízek z kukuřičného kuřete | bramborová kaše | salátek &nbsp;&nbsp;<br><br>JAHODY<br>Čatní | pusinka | sorbet | čokoláda</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 ff--2 fs--16" style={{"maxWidth":750}} action={"/contact"} fields={[{"name":"Celé jméno 1. host","type":"text","required":true,"placeholder":""},{"type":"select","name":"Výběr menu 1. host","required":true,"options":[{"id":"8ziabdw6r1in","value":"menu 1 - ryby"},{"id":"8vpju6fe5bq3","value":"menu 2 - maso"}],"placeholder":"Vyberte prosím menu pro 1. hosta"},{"type":"text","name":"Celé jméno 2. host"},{"type":"select","name":"Výběr menu 2. host","options":[{"id":"gc9q8tmuejfo","value":"menu 1 - ryby"},{"id":"cjm8jxqqkw2r","value":"menu 2 - maso"},{"id":"uml6gwtx5fa0","value":"dětské menu"}],"placeholder":"Vyberte prosím menu pro 2. hosta"},{"type":"text","name":"Celé jméno 3. host"},{"type":"select","name":"Výběr menu 3. host","options":[{"id":"3zyn2p8tw1wp","value":"menu 1 - ryby"},{"id":"uri9bsh9l1a","value":"menu 2 - maso"},{"id":"u4r48x5781ic","value":"dětské menu"}],"placeholder":"Vyberte prosím menu pro 3. hosta"},{"type":"text","name":"Celé jméno 4. host"},{"type":"select","name":"Výběr menu 4. host","options":[{"id":"irxxrxnc6maf","value":"menu 1 - ryby"},{"id":"z2z4wlykpjw2","value":"menu 2 - maso"},{"id":"n5lbdxel3pm3","value":"dětské menu"}],"placeholder":"Vyberte prosím menu pro 4. hosta"},{"type":"text","name":"Celé jméno 5. host"},{"type":"select","name":"Výběr menu 5. host","options":[{"id":"o7884nbiymdl","value":"menu 1 - ryby"},{"id":"fqqqx6uxa4dd","value":"menu 2 - maso"},{"id":"xcijh5ruhcqj","value":"dětské menu"}],"placeholder":"Vyberte prosím menu pro 5. hosta"},{"type":"plaintext"},{"type":"plaintext","content":"Pro ubytované v hotelu Aria je možné zajistit parkování za 895 Kč za místo a noc. Potřebujete zajistit parkování v hotelu Aria? "},{"type":"text","name":"Napište prosím ANO / NE","required":true},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1150}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"V případě dalších dotazů se prosím obraťte na naši svatební koordinátorku.<br>Eva: eva@ifconcept.cz"}>
              </Text>

              <Image style={{"maxWidth":""}} src={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=2000x_.png 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"Těšíme se na Vás!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/galerie\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}